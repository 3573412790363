import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {Router} from "@angular/router";


@Component({
  selector: 'app-footer-louderband',
  templateUrl: './footer-louderband.component.html',
  styleUrls: ['./footer-louderband.component.scss'],
})
export class FooterLouderbandComponent implements OnInit {
  @Input() isMobile: any;
  dataFooter: any[] = [];
  @Output() outData = new EventEmitter<any>();
  dataFooterMobile: any[] = [];


  constructor(
    private translate: TranslateService,
    private navCtrl: NavController,
    private router: Router
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.dataFooter = [
        {
          title: this.translate.instant('FOOTER_TITLE_ONE'),
          key: 'menu',
          id: 1,
          entries: [
            {
              name: this.translate.instant('FOOTER_COL_ONE_1'),
              id: 1,
              value: 'landing/landing-page'
            },
            {
              name: this.translate.instant('FOOTER_COL_ONE_2'),
              id: 2,
              value: 'home?search=%20&section=sound-market'
            },
            {
              name: this.translate.instant('FOOTER_COL_ONE_3'),
              id: 3,
              value: 'home?search=%20&section=search'
            },
            {
              name: this.translate.instant('FOOTER_COL_ONE_4'),
              id: 4,
              value: 'landing/landing-page2'
            },
            {
              name: this.translate.instant('FOOTER_COL_ONE_5'),
              id: 5,
              value: 'landing/terminos-y-condiciones'
            },
            // {
            //   name: this.translate.instant('FOOTER_COL_ONE_6'),
            //   id: 6
            // }
          ]
        },
        {
          title: this.translate.instant('FOOTER_TITLE_TWO'),
          key: 'courses',
          id: 2,
          entries: [
            {
              name: this.translate.instant('FOOTER_COL_TWO_1'),
              id: 1
            },
            {
              name: this.translate.instant('FOOTER_COL_TWO_2'),
              id: 2
            },
            {
              name: this.translate.instant('FOOTER_COL_TWO_3'),
              id: 3
            },
            {
              name: this.translate.instant('FOOTER_COL_TWO_4'),
              id: 4
            },
            /*{
              name: this.translate.instant('FOOTER_COL_TWO_5'),
              id: 5
            },*/
            // {
            //   name: this.translate.instant('FOOTER_COL_TWO_6'),
            //   id: 6
            // }
          ]
        },
        {
          title: this.translate.instant('FOOTER_TITLE_THREE'),
          key: 'class',
          id: 3,
          entries: [
            {
              name: this.translate.instant('FOOTER_COL_THREE_1'),
              id: 1,
              //searchTerm: 'search=%20&section=lesson&category=guitar'
            },
            {
              name: this.translate.instant('FOOTER_COL_THREE_2'),
              id: 2
            },
            {
              name: this.translate.instant('FOOTER_COL_THREE_3'),
              id: 3
            },
            {
              name: this.translate.instant('FOOTER_COL_THREE_4'),
              id: 4
            },
            // {
            //   name: this.translate.instant('FOOTER_COL_THREE_5'),
            //   id: 5
            // },
            // {
            //   name: this.translate.instant('FOOTER_COL_THREE_6'),
            //   id: 6
            // }
          ]
        },
        {
          title: this.translate.instant('FOOTER_TITLE_FOUR'),
          key: 'services',
          id: 4,
          entries: [
            {
              name: this.translate.instant('FOOTER_COL_FOUR_1'),
              id: 1
            },
            {
              name: this.translate.instant('FOOTER_COL_FOUR_2'),
              id: 2
            },
            {
              name: this.translate.instant('FOOTER_COL_FOUR_3'),
              id: 3
            },
            {
              name: this.translate.instant('FOOTER_COL_FOUR_4'),
              id: 4
            },
            // {
            //   name: this.translate.instant('FOOTER_COL_FOUR_5'),
            //   id: 5
            // },
            // {
            //   name: this.translate.instant('FOOTER_COL_FOUR_6'),
            //   id: 6
            // }
          ]
        },
        // {
        //   title: this.translate.instant('FOOTER_TITLE_FIVE'),
        //   key: 'Publica un aviso',
        //   id: 5,
        //   entries: [
        //     {
        //       name: this.translate.instant('FOOTER_COL_FIVE_1'),
        //       id: 1
        //     },
        //     {
        //       name: this.translate.instant('FOOTER_COL_FIVE_2'),
        //       id: 2
        //     },
        //     {
        //       name: this.translate.instant('FOOTER_COL_FIVE_3'),
        //       id: 3
        //     },
        //     {
        //       name: this.translate.instant('FOOTER_COL_FIVE_4'),
        //       id: 4
        //     },
        //     {
        //       name: this.translate.instant('FOOTER_COL_FIVE_5'),
        //       id: 5
        //     },
        //     {
        //       name: this.translate.instant('FOOTER_COL_FIVE_6'),
        //       id: 6
        //     }
        //   ]
        // }
      ];
      this.dataFooterMobile = [
        {
          title: this.translate.instant('FOOTER_TITLE_ONE'),
          key: 'menu',
          id: 1,
          entries: [
            {
              name: this.translate.instant('FOOTER_COL_ONE_1'),
              id: 1,
              value: 'landing/landing-page'
            },
            {
              name: this.translate.instant('FOOTER_COL_ONE_2'),
              id: 2,
              value: 'home?search=%20&section=sound-market'
            },
            {
              name: this.translate.instant('FOOTER_COL_ONE_3'),
              id: 3,
              value: 'home?search=%20&section=search'
            },
            {
              name: this.translate.instant('FOOTER_COL_ONE_4'),
              id: 4,
              value: 'landing/landing-page2'
            },
            {
              name: this.translate.instant('FOOTER_COL_ONE_5'),
              id: 5,
              value: 'landing/terminos-y-condiciones'
            },
            // {
            //   name: this.translate.instant('FOOTER_COL_ONE_6'),
            //   id: 6
            // }
          ]
        }
      ];
    }, 300);
   }


  onFocus(ev) {

  }

  updateSearchResults(ev) {

  }

  onBlur(ev) {

  }

  navigateTo(id: number , searchTerm: any) {
    if(id === 2){
      this.outData.emit({ focus: true });
      this.outData.emit({ old: true, view: 'home?search='+ searchTerm + '%20&section=Course' });
    } else if(id === 3){
      this.outData.emit({ focus: true });
      this.outData.emit({ old: true, view: 'home?search='+ searchTerm + '%20&section=lesson' });
    } else if(id === 4){
      this.outData.emit({ focus: true });
      this.outData.emit({ old: true, view: 'home?search='+ searchTerm + '%20&section=sound-market' });
    }
  }
  navigatePage(value) {
    console.log('Emitting:', value);
    if(value === 'home?search=%20&section=sound-market'){
      this.outData.emit({ old: true, view: value });
    } else if(value === 'home?search=%20&section=search'){
      this.outData.emit({ old: true, view: value });
    } else {
      this.router.navigate([value]);
      //this.navCtrl.navigateForward(value);
    }
  }
}
