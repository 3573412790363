import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';

@Component({
  selector: 'app-rating-louderband',
  templateUrl: './rating-louderband.component.html',
  styleUrls: ['./rating-louderband.component.scss'],
})
export class RatingLouderbandComponent implements OnInit {

  @Input() rating: number ;

  @Input() editing: boolean = false ;

  @Output() ratingChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  rate(index: number) {
    if (this.editing) {
      this.rating = index;
      this.ratingChange.emit(this.rating);
    }
  }

  getColor(index: number) {
    if(this.isAboveRating(index)){
      return '#D9D9D9';
    }
    switch (this.rating){
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return '#38AFEA';
      default:
        return '#D9D9D9';
    }
  }

  isAboveRating(index: number): boolean {
    return index > this.rating;
  }
  ngOnInit() {}

}
