import { OptionDiscComponent } from './../../popover/option-disc/option-disc';
import { LangService } from './../../services/lang.service';
import { RestService } from './../../services/rest.service';
import { EventsService } from './../../services/events.service';
import { ModalController, LoadingController, NavController, NavParams, ToastController, PopoverController } from '@ionic/angular';
import { Component, OnInit, } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SwiperOptions } from 'swiper';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-disc',
  templateUrl: './add-disc.component.html',
  styleUrls: ['./add-disc.component.scss'],
})
export class AddDiscComponent implements OnInit {
  record: any;
  recordList: any[] = [];
  list: any[] = [];
  instrumentsData: any;
  userTypesData: any[];
  ServicioNoOfrecido: any;
  music_select: any;
  espere: any;
  rec_select: any;
  mez_select: any;
  art_select: any;
  disc: { name: string; }[];
  discOriginal: { name: string; }[];
  guardaOk: any;
  searchTerm: any;
  isSearching: boolean;
  textPlace: string;
  mode_select: any;
  mode: any;
  NombreArtista: any;
  instrument: any;
  setDisc: any;
  listI: any[];
  dataDisc: any;
  recordListO: any[];
  userMode: any;
  mode_select_1: string;
  mode_select_2: string;
  mode_select_3: string;
  mode_select_4: string;
  instrument_guess: any;
  mode_select_5: string;
  productor: any;
  mezcla: any;
  grabacion: any;
  mus: any;
  musi: any;
  arte: any;
  edicion: any;
  est_grab: any;
  asis_grab: any;
  ing_grab: any;
  ing_mez: any;
  asis_mez: any;
  est_mez: any;
  ing_mas: any;
  asis_mas: any;
  est_mas: any;
  datarec: any;
  datamez: any;
  dataart: any;
  dataUser: any;
  mode_select_6: string;
  faltaInfo: string;
  eliminoOk: string;
  course: any;
  slidesCourseX: any;
  util: any;

  constructor(
    public navCtrl: NavController,
    public popoverCtrl: PopoverController,
    public navParams: NavParams,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    public events: EventsService,
    public rest: RestService,
    public translate: TranslateService,
    public translateProvider: LangService
  ) {
    this.dataDisc = this.navParams.get('dataDisc');
    this.dataUser = this.navParams.get('user');

    if (this.dataDisc) {
      this.searchTerm = this.dataDisc.name;
      this.setDisc = this.dataDisc;
      this.isSearching = false;
    }
    this.getIniTranslate();
    this.textPlace = this.NombreArtista;
    this.disc = [{ name: 'Nirvana Nevermind' }, { name: 'Red Hot Chili Peppers Californication' }];
    this.discOriginal = this.disc;
    this.loadInstruments();
  }
  ngOnInit() {
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad AddDiscPage');
    this.recordList = [
      { name: this.ing_grab, person: true },
      { name: this.asis_grab, person: true },
      { name: this.est_grab, person: false },
      { name: this.edicion, person: true }];
    this.recordListO = this.recordList;
    if (this.dataUser) {
      console.log('zzzzzz');
      console.log(this.dataUser);
      this.mode_select_5 = this.dataUser.disc_data_mus.length > 0 ? this.mus + ': ' + this.dataUser.disc_data_mus.toString() : null;
      this.mode_select_6 = this.dataUser.disc_data_musi.length > 0 ? this.musi + ': ' + this.dataUser.disc_data_musi.toString() : null;
      this.mode_select_1 = this.dataUser.disc_data_prod.length > 0 ? this.productor + ': ' + this.dataUser.disc_data_prod.toString() : null;
      this.mode_select_2 = this.dataUser.disc_data_rec.length > 0 ? this.grabacion + ': ' + this.dataUser.disc_data_rec.toString() : null;
      this.mode_select_3 = this.dataUser.disc_data_mez.length > 0 ? this.mezcla + ': ' + this.dataUser.disc_data_mez.toString() : null;
      this.mode_select_4 = this.dataUser.disc_data_art.length > 0 ? this.arte + ': ' + this.dataUser.disc_data_art.toString() : null;

      this.mode_select = (this.mode_select_5 ? this.mode_select_5 + '; ' : '')
        + (this.mode_select_6 ? this.mode_select_6 + '; ' : '')
        + (this.mode_select_1 ? this.mode_select_1 + '; ' : '')
        + (this.mode_select_2 ? this.mode_select_2 + '; ' : '')
        + (this.mode_select_3 ? this.mode_select_3 + '; ' : '')
        + (this.mode_select_4 ? this.mode_select_4 : '');
      console.log(this.mode_select);
    }
  }

  startSearch() {
    this.disc = [];
    if (!this.searchTerm) {
      this.setDisc = null;
      return this.disc;
    }
    let params = {
      search: this.searchTerm
    };
    this.rest._get('discs/search?' + params).subscribe(resx => {
      const res: any = resx.body;
      console.log(res);
      this.setDisc = null;
      let auxRes: any = res;
      auxRes = auxRes.data.discs;
      console.log(auxRes.length);
      for (let i = 0; i < auxRes.length; i++) {
        const element = {
          name: auxRes[i].name,
          cover: auxRes[i].images[1].url,
          artist: auxRes[i].artists[0].name,
          id: auxRes[i].id,
        }
        this.disc.push(element);
      }
      console.log(this.disc);

    }, (err) => {
      //this.loading.dismiss();
      console.error(err);
    });
    this.isSearching = true;
    this.disc = this.disc.filter((item) => {
      return item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
    });

  }
  selectDisc(item) {
    this.searchTerm = item.name;
    this.setDisc = item;
    this.isSearching = false;
  }
  changeMode() {
    console.log(this.instrument);
    this.mode_select_5 = this.mus + ': ' + this.instrument.toString();
    this.showMode();
  }

  changeModeGuess() {
    console.log(this.instrument_guess);
    this.mode_select_6 = this.musi + ': ' + this.instrument_guess.toString();
    this.showMode();
  }

  changeModeRecord2() {
    console.log(this.recordList);
    this.mode_select = this.mus + ': ' + this.instrument.toString();
  }


  changeModeRecord() {
    this.recordList = this.recordListO;
    console.log(this.record);
    for (let index = 0; index < this.record.length; index++) {
      if (this.record[index].personal) {
        this.recordList = [
          { name: this.ing_grab, person: true },
          { name: this.asis_grab, person: true },
          { name: this.edicion, person: true }];

      } else {
        this.recordList = [
          { name: this.est_grab, person: false }];
      }
    }

  }

  public loadInstruments() {
    return this.rest._getLocal('https://api.louderband.com/api/instruments?fields=instrument_id%2C%20name&per-page=0&sort=name').subscribe(async (response) => {
      const res: any = response.body
      this.instrumentsData = res.items;
      let list = this.instrumentsData;
      this.listI = [];
      for (let index = 0; index < list.length; index++) {
        let aux = {
          id: list[index].id,
          name: this.translate.instant(list[index].name),
          isChecked: false
        }
        this.listI.push(aux);
      }
      console.log(this.listI);
      if (this.dataUser && this.dataUser.disc_data_mus.length > 0) {
        this.instrument = this.getTrans(this.dataUser.disc_data_mus);
      }
      if (this.dataUser && this.dataUser.disc_data_musi.length > 0) {
        this.instrument_guess = this.getTrans(this.dataUser.disc_data_musi);
      }
      console.log(this.instrument);
      return res.items;
    }, (err) => console.error(err));
  }


  public getUserTypes() {
    let params = {
      sort: 'name',
      complete_project: 'N',
    };

    this.rest._get('services/user-types?complete_project=N&sort=name').subscribe(async (response) => {
      const res: any = response.body;
      if (res.items.length > 0) {
        this.userTypesData = this.getArryTranslate(res.items);
        this.search('');
        let xuserTypesData = res.items;
        console.log(JSON.stringify(xuserTypesData));
      } else {
        this.userTypesData = [];
        this.userTypesData.push({ user_type_id: 0, name: this.ServicioNoOfrecido });
        //this.selectedUserType = undefined;
      }
    });
  }

  search(word: any) {
    // Reset employess array back to all of the items
    // if the search term is an empty string return all items

    if (!word) {
      return this.userTypesData;
    }
    //
    this.userTypesData = this.userTypesData.filter((item) => {
      return item.name.toLowerCase().indexOf(word.toLowerCase()) > -1;
    });
  }

  getArryTranslate(items: any) {
    let language = this.translateProvider.getTranslateLanguage();
    this.translate.use(language);
    let aux = items;
    let arrayReturn: any[] = [];
    for (let i = 0; i < aux.length; i++) {
      let a = aux[i];
      a.name = this.translate.instant(String(aux[i].name));
      arrayReturn.push(a);
    }
    console.log(JSON.stringify(arrayReturn));
    return arrayReturn;
  }

  getOld(type) {
    let auxArray: any;
    switch (type) {
      case 'pro':
        auxArray = this.dataUser.disc_data_prod
        break;
      case 'rec':
        auxArray = this.dataUser.disc_data_rec
        break;
      case 'mez':
        auxArray = this.dataUser.disc_data_mez
        break;
      case 'art':
        auxArray = this.dataUser.disc_data_art
        break;
      case 'mus':
        auxArray = this.dataUser.disc_data_mus
        break;
      case 'musi':
        auxArray = this.dataUser.disc_data_musi
        break;
      default:
        break;
    }

    for (let i = 0; i < this.list.length; i++) {
      for (let j = 0; j < auxArray.length; j++) {
        console.log(this.list[i].name.toLowerCase().trim())
        console.log(auxArray[j].toLowerCase().trim())
        if (this.list[i].name.toLowerCase().trim() == auxArray[j].toLowerCase().trim()) {
          this.list[i].check = true;
        }
      }

    }
    console.log(this.list);
    return this.list;
  }

  getTrans(values: any) {
    let b = values[0].split(', ');
    let newValues = [];

    for (let item of b) {
      //console.log('ori ' +item.trim(), 'new ' + this.translate.instant(item.trim()));
      newValues.push(this.translate.instant(item.trim()));
    }
    return newValues;
  }

  select(mode: any) {

    switch (mode) {
      case 'pro':
        this.list = [
          { name: this.productor }];
        if (this.dataUser) {
          this.list = this.getOld('pro');
        }
        setTimeout(() => {
          this.openPopOver(mode);
        }, 300);

        break;
      case 'rec':
        this.list = [
          { name: this.ing_grab, person: true },
          { name: this.asis_grab, person: true },
          { name: this.est_grab, person: false },
          { name: this.edicion, person: true }];
        console.log(this.list);
        if (this.dataUser) {
          this.getOld('rec');
        }
        this.openPopOver(mode);

        break;
      case 'mez':
        this.list = [
          { name: this.ing_mez, person: true },
          { name: this.asis_mez, person: true },
          { name: this.est_mez, person: false },
          { name: this.edicion, person: true },
          { name: this.ing_mas, person: true },
          { name: this.asis_mas, person: true },
          { name: this.est_mas, person: false }];
        this.openPopOver(mode);
        break;
      case 'art':
        this.list = [
          { name: 'Diseño', person: true },
          { name: 'Fotografía', person: true }];
        if (this.userMode != 'no-person') {

          this.openPopOver(mode);
        }
        break;

      default:
        break;
    }
  }


  checkList() {

  }

  async openPopOver(mode) {

    if (this.userMode) {
      console.log(this.userMode);
      for (let index = 0; index < this.list.length; index++) {
        this.list[index].hide = false;
        if (!this.list[index].person && this.userMode == 'person') {
          this.list[index].hide = true;
        }
        if (this.list[index].person && this.userMode == 'no-person') {
          console.log(index);
          this.list[index].hide = true;
        }
      }
      let auxList = [];
      for (let index = 0; index < this.list.length; index++) {
        if (!this.list[index].hide) {
          auxList.push(this.list[index]);
        }
      }
      this.list = auxList;
    }
    console.log(this.list);
    let prev: any;
    switch (mode) {
      case 'pro':
        prev = this.mode_select_1 ? true : null;
        break;
      case 'rec':
        prev = this.datarec ? this.datarec : null
        break;
      case 'mez':
        prev = this.datamez ? this.datamez : null
        break;
      case 'art':
        prev = this.dataart ? this.dataart : null
        break;
      default:
        break;
    }
    const popover = await this.popoverCtrl.create({
      component: OptionDiscComponent,
      componentProps: { list: this.list, mode: mode, prev: prev },
      mode: 'ios',
      cssClass: 'pop-filter'
    });

    await popover.present();

    const { data } = await popover.onDidDismiss();
    if (data) {
      console.log(data);
      this.mode = mode;
      this.userMode = data.userMode;
      switch (mode) {
        case 'pro':
          if (data.value) {
            this.mode_select_1 = this.productor + ': ' + this.productor;
          } else {
            this.mode_select_1 = null;
          }

          break;
        case 'rec':
          this.mode_select_2 = this.grabacion + ': ' + data.value;
          this.datarec = data.getO;
          break;
        case 'mez':
          this.mode_select_3 = this.mezcla + ': ' + data.value;
          this.datamez = data.getO;
          break;
        case 'art':
          this.mode_select_4 = this.arte + ': ' + data.value;
          this.dataart = data.getO;
          break;
        default:
          break;
      }
      this.showMode();
    }
  }



  async delete() {
    const loader = await this.loadingCtrl.create({
      message: this.espere,
    });
    await loader.present();
    this.rest._delete('discs/' + this.dataDisc.id).subscribe(resxv => {
      const res:any = resxv.body;
      console.log(res);
      loader.dismiss();
      if (res.estado == 'success') {
        this.navCtrl.pop();
        this.presentToast(this.eliminoOk);
        this.events.publish('disc:user', '');
      } else {
        // alert(JSON.stringify(res));
        console.log(res.mensaje);
      }
    }, (err) => {
      loader.dismiss();
      //this.loading.dismiss();
      // alert(JSON.stringify(err));
      console.log(err);
    });
  }

  async save() {

    //post
    console.log(this.mode_select_1);
    console.log(this.mode_select_2);
    console.log(this.mode_select_3);
    console.log(this.mode_select_4);
    this.showMode();
    let array = [];
    if (this.mode_select_1) {
      array.push(this.mode_select_1);
    }
    if (this.mode_select_2) {
      array.push(this.mode_select_2);
    }
    if (this.mode_select_3) {
      array.push(this.mode_select_3);
    }
    if (this.mode_select_4) {
      array.push(this.mode_select_4);
    }
    if (this.mode_select_5) {
      array.push(this.mode_select_5);
    }
    if (this.mode_select_6) {
      array.push(this.mode_select_6);
    }

    console.log(this.mode_select);
    if (!this.setDisc || !this.mode_select) {
      this.presentToast(this.faltaInfo);
      return;
    }
    const loader = await this.loadingCtrl.create({
      message: '',
    });
    await loader.present();
    let params = {
      player_info: this.setDisc.id,
      job: array
    };
    // alert(JSON.stringify(params));
    if (this.dataUser) {
      this.rest._put('discs/' + this.dataDisc.id, params).subscribe(rev => {
        const res:any = rev.body;
        console.log(res);
        loader.dismiss();
        if (res.estado == 'success') {
          this.navCtrl.pop();
          this.presentToast(this.guardaOk);
          this.events.publish('disc:user', '');
        } else {
          // alert(JSON.stringify(res));
          console.log(res.mensaje);
        }
      }, (err) => {
        loader.dismiss();
        //this.loading.dismiss();
        // alert(JSON.stringify(err));
        console.log(err);
      });
    } else {
      this.rest._post('discs', params).subscribe(resxv => {
        const res:any = resxv.body;
        console.log(res);
        loader.dismiss();
        if (res.estado == 'success') {
          this.navCtrl.pop();
          this.presentToast(this.guardaOk);
          this.events.publish('disc:user', '');
        } else {
          alert(JSON.stringify(res));
          console.log(res.mensaje);
        }
      }, (err) => {
        loader.dismiss();
        //this.loading.dismiss();
        alert(JSON.stringify(err));
        console.log(err);
      });
    }

  }

  private showMode() {
    this.mode_select =
      (this.mode_select_5 ? this.mode_select_5 + '; ' : '')
      + (this.mode_select_6 ? this.mode_select_6 + '; ' : '')
      + (this.mode_select_1 ? this.mode_select_1 + '; ' : '')
      + (this.mode_select_2 ? this.mode_select_2 + '; ' : '')
      + (this.mode_select_3 ? this.mode_select_3 + '; ' : '')
      + (this.mode_select_4 ? this.mode_select_4 : '');
  }

  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'bottom'
    });


    await toast.present();
  }

  actionBack() {
    this.navCtrl.pop();
  }

  getIniTranslate() {

    let language = this.translateProvider.getTranslateLanguage();
    this.translate.use(language);
    this.ServicioNoOfrecido = this.translate.instant("HomeSoundMarketPage.ServicioNoOfrecido");
    //this.No = this.translate.instant("No");
    //this.Si = this.translate.instant("Si");
    this.NombreArtista = this.translate.instant("AddDiscCommonsPage.NombreArtista");
    this.productor = this.translate.instant("Productor");
    this.mezcla = this.translate.instant("Mezcla");
    this.grabacion = this.translate.instant("Grabación");
    this.mus = this.translate.instant("Músico");
    this.musi = this.translate.instant("Músico invitado");
    this.arte = this.translate.instant("Arte");
    this.edicion = this.translate.instant("Edición");
    this.est_grab = this.translate.instant("Estudio de grabación");
    this.asis_grab = this.translate.instant("Asistente de grabación");
    this.ing_grab = this.translate.instant("Ingeniero de grabación");
    this.ing_mez = this.translate.instant("Ingeniero de mezcla");
    this.asis_mez = this.translate.instant("Asistente de mezcla");
    this.est_mez = this.translate.instant("Estudio de mezcla");
    this.est_mas = this.translate.instant("Estudio de mastering");
    this.asis_mas = this.translate.instant("Asistente de mastering");
    this.ing_mas = this.translate.instant("Ingeniero de mastering");
    this.guardaOk = this.translate.instant("AddDiscCommonsPage.guardaOk");
    this.espere = this.translate.instant("AddDiscCommonsPage.espere");
    this.faltaInfo = this.translate.instant("AddDiscCommonsPage.faltaInfo");
    this.eliminoOk = this.translate.instant("AddDiscCommonsPage.eliminoOk");

    // this.DeseasElimiar = this.translate.instant("Ingeniero de grabación");


  };

}
