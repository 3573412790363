import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {ProfileRedirectResolver} from './resolver/profile-redirect.resolver';
import {ResponseSubscriptionsPageModule} from './pages/response-subscriptions/response-subscriptions.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/registry/registry.module').then(m => m.RegistryPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registry/registry.module').then(m => m.RegistryPageModule)
  },
  {
    path: 'register-continue',
    loadChildren: () => import('./pages/registry-continue/registry-continue.module').then(m => m.RegistryContinuePageModule)
  },
  {
    path: 'continuar-resgistro',
    loadChildren: () => import('./pages/registry-continue/registry-continue.module').then(m => m.RegistryContinuePageModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesPageModule)
  },
  {
    path: 'cursos',
    loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesPageModule)
  },
  {
    // The following route is used for both services of `type` `sound` or `lesson`
    path: 'services/:type/:id',
    loadChildren: () => import('./pages/services-details/services-details.module').then(m => m.ServicesDetailsModule)
  },
  {
    // The following route is used for both services of `type` `sound` or `lesson`
    path: 'servicios/:type/:id',
    loadChildren: () => import('./pages/services-details/services-details.module').then(m => m.ServicesDetailsModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'mensajes',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'chat/:chatroom',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'mensajes/:chatroom',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'chat/user/:user',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'mensajes/usuario/:user',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'chat/user/:user/:message',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'mensajes/usuario/:user/:message',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'browse/:slug',
    loadChildren: () => import('./pages/browse/browse.module').then(m => m.BrowsePageModule)
  },
  {
    path: 'landing/:slug',
    loadChildren: () => import('./pages/browse/browse.module').then(m => m.BrowsePageModule)
  },
  {
    path: 'post-music',
    loadChildren: () => import('./pages/post-music/post-music.module').then(m => m.PostMusicModule)
  },
  {
    path: 'publicar',
    loadChildren: () => import('./pages/post-music/post-music.module').then(m => m.PostMusicModule)
  },
  {
    path: 'post-services-details/:subtype/:id',
    loadChildren: () => import('./pages/post-services-details/post-services-details.module').then(m => m.PostServicesDetailsModule)
  },
  {
    path: 'busquedas-publicadas/:subtype/:id',
    loadChildren: () => import('./pages/post-services-details/post-services-details.module').then(m => m.PostServicesDetailsModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'notificaciones',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'disc-details/:id',
    loadChildren: () => import('./pages/disc-details/disc-details.module').then( m => m.DiscDetailsPageModule)
  },
  {
    path: 'creditos/:id',
    loadChildren: () => import('./pages/disc-details/disc-details.module').then( m => m.DiscDetailsPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./pages/subscriptions/suscriptions.module').then(m => m.SubscriptionsPageModule)
  },
  {
    path: 'suscripciones',
    loadChildren: () => import('./pages/subscriptions/suscriptions.module').then(m => m.SubscriptionsPageModule)
  },
  {
    path: 'response-subscriptions',
    loadChildren: () => import('./pages/response-subscriptions/response-subscriptions.module').then(m => m.ResponseSubscriptionsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    resolve: { redirect: ProfileRedirectResolver }
  },
  {
    path: 'perfiles/:id',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    resolve: { redirect: ProfileRedirectResolver }
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    path: 'mi-perfil',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
