import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedVarModalService {
  public showModal: boolean;

  constructor() { }

  updateVarShowModal(newValue: boolean){
    this.showModal = newValue;
  }
}


