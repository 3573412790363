import { UtilService } from '../../services/util.service';
import { RestService } from '../../services/rest.service';
import { Component } from '@angular/core';
import { NavParams, ToastController, LoadingController, PopoverController, NavController } from '@ionic/angular';
// Pixel library importing
import { PixelService } from 'ngx-pixel';

@Component({
  selector: 'page-option-subscribe',
  templateUrl: 'option-subscribe.component.html',
  styleUrls: ['option-subscribe.component.scss']
})
export class OptionSubscribeComponent {
  futureEmail: any;
  notAccount: boolean;
  errorEmail: any;
  couponCode: any;
  subscriptionId: any;
  errorMsg: any;
  dataCoupon: any;
  isIos: boolean;
  user: any;

  constructor(
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    public rest: RestService,
    public util: UtilService,
    public popoverController: PopoverController,
    public navCtrl: NavController,
    private pixel: PixelService,
    public navParams: NavParams) {
    this.notAccount = this.navParams.data.notAccount;
    this.subscriptionId = this.navParams.data.subscriptionId;

    this.rest.getStorage('user').then((val) => {
      this.user = val;
    });

  }

  goToLogin(){
    this.close();
    this.navCtrl.navigateRoot('/login')
  }

  async checkCoupon() {
    let loading = await this.loadingCtrl.create({});
    loading.present();
    const body = {
      id: this.subscriptionId,
      type: 'SUBSCRIPTION',
      coupon: this.couponCode,
      force_web_price: 1,
    };
    this.dataCoupon = null;
    this.errorMsg = null;

    let apiHelp = '';
    if (this.user != null && (this.user.premium === 'S' || 'N')) {
      apiHelp = 'coupons/validate';
    } else {
      apiHelp = 'coupons/guest/validate';
    }

    this.rest._post(apiHelp, body).subscribe(async response => {
      const res: any = response.body;
      if (res) {
        loading.dismiss();
        if (res.estado == 'success') {
          this.dataCoupon = res;
        } else {
          this.errorMsg = res.mensaje
        }
      }
    }, async (error) => {
      loading.dismiss();
      this.errorMsg = error.error.mensaje
      console.log(error);
    });
  }

  async buy() {
    const email = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");
    if (!this.notAccount) {
      this.popoverController.dismiss({ value: 'buy', futureEmail: this.futureEmail, coupon: this.dataCoupon })
    } else {
      if (email.test(this.futureEmail)) {
        this.popoverController.dismiss({ value: 'buy', futureEmail: this.futureEmail, coupon: this.dataCoupon })
      } else {
        let toast = await this.toastCtrl.create({
          message: this.errorEmail,
          duration: 3000,
          position: 'bottom'
        });
        toast.present();
      }
    }
  }

  gift() {
    this.popoverController.dismiss({ value: 'gift', futureEmail: this.futureEmail, coupon: this.dataCoupon })
  }


  close() {
    this.popoverController.dismiss();
  }


}
