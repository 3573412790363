import { Storage } from '@ionic/storage-angular';
import { EventsService } from './events.service';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { throwError, Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';


@Injectable({
	providedIn: 'root'
})
export class RestService {

	api = environment.api;
	apiSM = environment.api_sm;
	apiL = environment.api_l;
	forceMobile = 1;
	headers = new Headers();
	louToken: any = '';

	constructor(
		public http: HttpClient,
		public events: EventsService,
		public plt: Platform,
		public util: UtilService,
		public storage: Storage,
		public navCtrl: NavController) {
		this.init();
		console.log('ws');
		this.getStorage('token').then((val) => {
			console.log('val', val);
			this.louToken = val;
		});
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const val = window.localStorage.getItem('louderbandToken');
		let authReq = req;

		if (val) {
			authReq = authReq.clone(
				{
					headers: req.headers.set('Authorization', 'Bearer ' + val)

				});
		}



		return next.handle(authReq).pipe(
			tap((response: any) => {
				console.log('intercept response ::', response.status);
				// console.log(JSON.stringify(response));
			}, (error) => {
				console.log('intercept error ::', error.status);
				console.log(JSON.stringify(error));
				if (error.status === 401) {
					this.navCtrl.navigateRoot('/login');
				}
			})
		);
	}


	// GRL GET PARAMS
	_get(query, api?) {
		let path = this.api
		if (api == 's') {
			path = this.apiSM;
		} else if (api == 'l') {
			path = this.apiL;
		}
		return this.http.get(path + query, { observe: 'response' });
	}


	// POST PARAMS
	_post(query, body, api?) {
		let path = this.api
		if (api == 's') {
			path = this.apiSM;
		} else if (api == 'l') {
			path = this.apiL;
		}
		return this.http.post(path + query, body, { observe: 'response' });
	}

	// POST PARAMS
	_delete(query, body?) {
		return this.http.delete(this.api + query, { observe: 'response' });
	}


	// POST PARAMS
	_put(query, body) {
		return this.http.put(this.api + query, body, { observe: 'response' });
	}


	// POST PARAMS
	_postLogin(query, params) {
		let headers = new HttpHeaders();
		headers = headers.set('Authorization', 'Basic ' + btoa(params.email + ':' + params.password));
		return this.http.post(this.api + query, params, { observe: 'response', headers });
	}

	async init() {
		await this.storage.create();
	}

	async storageSet(name, value) {
		const val = await this.storage.set(name, value);
		return val;
	}

	async removeSet(name) {
		const val = await this.storage.remove(name);
		return val;
	}

	async getStorage(name) {
		const val = await this.storage.get(name);
		return val;
	}

	_getLocal(query) {
		return this.http.get(query, { observe: 'response', responseType: 'text' });
	}

}
