import { Component, OnInit } from '@angular/core';
import {NavController, Platform} from "@ionic/angular";
import { SharedVarModalService } from "../../services/shared-var-modal.service";

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss'],
})
export class AddPostComponent implements OnInit {
  isMobile: boolean;

  constructor(
    public sharedVarModalService: SharedVarModalService,
    public platform: Platform,
    public navCtrl: NavController,
  ) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.isMobile = true;
    }
  }

  ngOnInit() {
    this.isMobile = this.platform.is('android') || this.platform.is('ios');
    this.sharedVarModalService.updateVarShowModal(false);
  }

  ionViewDidEnter() {
    this.isMobile = this.platform.is('android') || this.platform.is('ios');
    this.sharedVarModalService.updateVarShowModal(false);
  }
  hiddenModal() {
    this.sharedVarModalService.updateVarShowModal(false);
  }

  gotoPostClass() {
    this.navCtrl.navigateForward('/publicar/clase');
    this.sharedVarModalService.updateVarShowModal(false);
  }
  gotoPostService() {
    this.navCtrl.navigateForward('/publicar/servicio');
    this.sharedVarModalService.updateVarShowModal(false);
  }
  gotoPostMusician() {
    this.navCtrl.navigateForward('/publicar/musico');
    this.sharedVarModalService.updateVarShowModal(false);
  }

}


