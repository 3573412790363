import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {RestService} from '../services/rest.service';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ProfileRedirectResolver implements Resolve<any> {
  constructor(public router: Router,
              public rest: RestService,
              public loadingCtrl: LoadingController,) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let id = route.paramMap.get('id');
    id = id.split('-')[0];

    this.rest._get('accounts/user-information/' + id, 'get').subscribe(response => {
      if(response) {
        const res: any = response.body;
        let userName = res.name.toLowerCase();
        userName = userName.trim().replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const newUrl = `/perfiles/${id}-${userName}`;
        this.router.navigateByUrl(newUrl, {replaceUrl: true});
      }
    }, (err) => {
      console.error('There was an error loading this data', err);
    });
    return of(null);
  }
}
