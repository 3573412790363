import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { EventsService } from './events.service';
import { Storage } from '@ionic/storage-angular';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable({
  providedIn: 'root'
})
export class LangService {
  language: any;
  auxBand: boolean;
  constructor(
    public translate: TranslateService,
    public storage: Storage,
    public events: EventsService) {
    this.init();
    console.log('Hello TranslateProvider Provider');
    this.storage.get('lang').then((value: any) => {
      this.language = value;
    }
    ).catch(err => console.log(err));
  }
  
  async init() {
    await this.storage.create();
  }

  setAuxBand(val: boolean) {
    this.auxBand = val;
  }

  setTranslateLanguage(language: string) {

    this.language = language;
    console.log('language select= ' + this.language);
    this.events.publish('menu:language', '');
    this.translate.use(language);
    this.setLanguaje(language);
    // this.initialiseTranslation();
    this.events.publish('util:msg', '');
  }

  use(lang) {
    this.translate.use(lang);
  }

  upLang() {
    this.events.publish('language:', '');
  }

  setLanguaje(language: string): any {
    this.language = language;
    console.log('language ', language);
    this.storage.set('lang', language);
    this.language = language;
    console.log('language ', language);
  }

  getLanguajeStorage() {
    return this.storage.get('lang')
      .then(
        (value: any) => {
          return value;
        }
      ).catch(
        // toast
        err => console.log(err)
      );
  }

  instant(value) {
    return this.translate.instant(value);
  }



  getTranslateLanguage() {
    if (this.language) {
      return this.language;
    } else {
      return null;
    }
  }

}
