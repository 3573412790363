// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: 'http://127.0.0.1:8000/api/',
  api: 'https://v2.louderband.com/api/',
  api_sm: 'https://api.louderband.com/api-sound-market/',
  api_l: 'https://api.louderband.com/api-lessons/',
  instrumento_id: 77,
  ing_sonido: 78,
  null_category: 146,
  null_user: 21,
  banda_id: 19,
  firebase: {
    projectId: 'louderband-app',
    appId: '1:798973480047:web:ff2286100fa01408b6519a',
    databaseURL: 'https://louderband-app.firebaseio.com',
    storageBucket: 'louderband-app.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAifcFqLqJqzecKaGe0RNU3QD8LQh9KLE4',
    authDomain: 'louderband-app.firebaseapp.com',
    messagingSenderId: '798973480047',
    measurementId: 'G-XH8XX1JBCQ',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
