import { Component } from '@angular/core';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'page-option-buy-data',
  templateUrl: 'option-buy-data.component.html',
  styleUrls: ['option-buy-data.component.scss']
})
export class OptionBuyDataComponent {
  name: string;
  email: string;
  errorEmail: 'Verifica que ingresaste un email correcto';

  constructor(
    public translate: TranslateService,
    public toastCtrl: ToastController,
    public popoverController: PopoverController,
    public navParams: NavParams) {
    this.name = '';
    this.email = '';
  }

  async buy() {
    const email = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");
    
    console.log(this.email);

    if (email.test(this.email)) {
      let data = {
        name: this.name,
        email: this.email
      };

      this.popoverController.dismiss({ data: data })
    } else {
      let toast = await this.toastCtrl.create({
        message: this.errorEmail,
        duration: 3000,
        position: 'bottom'
      });
      await toast.present();
    }

  }

  close() {
    this.popoverController.dismiss();
  }

  getValid() {
    if (this.email.length && this.name.length) {
      return false;
    } else {
      return true;
    }
  }
}
