import { ModalController, LoadingController, NavController, NavParams } from '@ionic/angular';
import { Component, OnInit, } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SwiperOptions } from 'swiper';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
})
export class MoreInfoComponent implements OnInit {
  course: any;
  isMobile: any;

  slidesCourseX: SwiperOptions = {
    initialSlide: 1,
    slidesPerView: 1.7,
    spaceBetween: 9,
    centeredSlidesBounds: true,
    slideToClickedSlide: true
  };


  constructor(
    public modalCtrl: ModalController,
    public params: NavParams,
    public util: UtilService,
    public domSanitizer: DomSanitizer,
    public navCtrl: NavController,
    public loading: LoadingController) {
    this.course = this.params.data.item;
    this.isMobile = this.params.data.isMobile;
  }

  ngOnInit() {
    //console.log('datos',this.course);
  }

  close() {
    this.modalCtrl.dismiss();
  }

  next() {

  }

  goToDisc(item, owner) {
    console.log(item, owner);
  }

  goToProfile(val){
    this.modalCtrl.dismiss();
    this.navCtrl.navigateBack('perfiles/'+ val);
  }



}
