import { ModalController, LoadingController, NavController, NavParams } from '@ionic/angular';
import { Component, OnInit, } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-c',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  item: any;
  safeUrl: any;
  isMobile: any;

  constructor(
    public modalCtrl: ModalController,
    public params: NavParams,
    public domSanitizer: DomSanitizer,
    public loading: LoadingController) {
    this.item = this.params.data.item;
    this.isMobile = this.params.data.isMobile;
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/462433543?autoplay=1');
  }

  ngOnInit() { }

  askmeLater() {
    this.modalCtrl.dismiss(false);
  }

  next() {

  }



}
