import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterLouderbandComponent } from './footer-louderband/footer-louderband.component';
import { HeaderLouderbandComponent } from './header-louderband/header-louderband.component';
import { TranslateModule } from '@ngx-translate/core';
import { SearchLouderbandComponent } from './search-louderband/search-louderband.component';
import { SwiperModule } from 'swiper/angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CouserUserLouderbandComponent } from './course-user-louderband/course-user-louderband.component';
import {RatingLouderbandComponent} from './rating-louderband/rating-louderband.component';
import {ModalModule} from "../modal/modal.module";

@NgModule({
    declarations: [
        HeaderLouderbandComponent,
        FooterLouderbandComponent,
        SearchLouderbandComponent,
        CouserUserLouderbandComponent,
        RatingLouderbandComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    InfiniteScrollModule,
    SwiperModule,
    ModalModule,
  ],
  exports: [
    HeaderLouderbandComponent,
    FooterLouderbandComponent,
    SearchLouderbandComponent,
    CouserUserLouderbandComponent,
    RatingLouderbandComponent
  ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class ComponentsModule { }
