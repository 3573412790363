import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VariableNavBarService {
  public onHomePage: boolean;
  public onCoursePage: boolean;
  public onServicePage: boolean;
  public onChatPage: boolean;
  public onSearchBar: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  updateVarOnHomePage() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const segments = event.url.split('/');
        const lastSegment = segments[segments.length - 1];

        if (lastSegment === 'home') {
          this.onHomePage = true;
        } else if (lastSegment !== 'home') {
          this.onHomePage = false;
        }
      }
    });
  }

  updateVarOnCoursePage() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onCoursePage = event.url.includes('/cursos');
      }
    });
  }

  updateVarOnServicePage() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onServicePage = event.url.endsWith('&section=sound-market');
      }
    });
  }

  updateVarOnChatPage() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onChatPage = event.url.includes('/mensajes');
      }
    });
  }

  updateVarOnSearchBar(newValue: boolean) {
    this.onSearchBar = newValue;
  }
}
