import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-msgok',
  templateUrl: './msgok.component.html',
  styleUrls: ['./msgok.component.scss']
})
export class MsgOkComponent implements OnInit {
  msgTitle: any;
  constructor(public navParams: NavParams,
    public popOverCtrl: PopoverController) {
    this.msgTitle = this.navParams.data.msgTitle;
   }

  ngOnInit(): void {
  }

  async confirm() {
    this.popOverCtrl.dismiss(true);
  }

}
