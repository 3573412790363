import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MsgOkComponent } from './msgok/msgok.component';
import { FilterComponent } from './filter/filter.component';
import { OptionBuyComponent } from './option-buy/option-buy.component';
import { OptionSubscribeComponent } from './option-subscribe/option-subscribe.component';
import { OptionBuyDataComponent } from './option-buy-data/option-buy-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { OptionDiscComponent } from './option-disc/option-disc';

@NgModule({
    declarations: [
        MsgOkComponent,
        FilterComponent,
        OptionDiscComponent,
        OptionBuyComponent,
        OptionBuyDataComponent,
        OptionSubscribeComponent,
        OptionDiscComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule
    ],
    exports: [
        MsgOkComponent,
        FilterComponent,
        OptionDiscComponent,
        OptionBuyComponent,
        OptionBuyDataComponent,
        OptionSubscribeComponent,
        OptionDiscComponent

    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class PopoverModule { }
