import { UtilService } from './../../services/util.service';
import { RestService } from './../../services/rest.service';
import {ModalController, NavController, Platform, PopoverController} from '@ionic/angular';
import {Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SharedVarModalService } from "../../services/shared-var-modal.service";
import { collection,  query, where, updateDoc, doc, onSnapshot, limit, deleteDoc, orderBy } from 'firebase/firestore';
import {VariableNavBarService} from "../../services/variable-nav-bar.service";
import {ScrollHeaderService} from '../../services/scroll-header.service';

@Component({
  selector: 'app-header-louderband',
  templateUrl: './header-louderband.component.html',
  styleUrls: ['./header-louderband.component.scss'],
})
export class HeaderLouderbandComponent implements OnInit, OnDestroy {
  search: any;
  search_section: any;
  fullAndroid = false;
  isMobile: boolean;
  timeout = null;
  lastLocation = null;
  isOpenMobile = false;
  isOpenDesktop = false;
  isOpenNotification = false;
  isOpenNotificationNested = false;
  isOpenModalLogo = false;
  isMacOS = false;
  @Output() outData = new EventEmitter<any>();
  @ViewChild('popoverMobile') popoverMobile;
  @ViewChild('popoverDesktop') popoverDesktop;
  @ViewChild('popoverNotification') popoverNotification;
  @ViewChild('popoverNotificationNested') popoverNotificationNested;
  modalShowRegisterTelco: boolean;
  email = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");
  unsubscribe: any;
  notifications: any[] = [];
  filteredNotifications: any[] = [];
  user: any;
  localUserID: any;
  currentUserId: string;
  selectedNotificationType: 'all' | 'read' | 'unread' = 'all';
  itemNotification: any;

  constructor(
    public sharedVarModalService: SharedVarModalService,
    public platform: Platform,
    public navCtrl: NavController,
    public util: UtilService,
    public location: Location,
    public router: Router,
    public rest: RestService,
    public activatedRoute: ActivatedRoute,
    private popoverController: PopoverController,
    private modalController: ModalController,
    public variableNavBarService: VariableNavBarService,
    public scrollHeaderService: ScrollHeaderService
  ) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.isMobile = true;
    }

    this.activatedRoute.queryParams.subscribe(params => {
      console.log('queryParams', params);
      if (params['search'] || params['section']) {
        this.search = params['search'];
        this.search_section = params['section'];

        console.log('From Header Search', 'Location', 'Search Term:', this.search, 'Search Section:', this.search_section);

        this.updateSearchResults(null);
        this.onFocus(null);
      }
    });

    this.isMacOS = (navigator.userAgent.indexOf('Macintosh') > 0 && navigator.userAgent.indexOf('Safari') > 0 && navigator.userAgent.indexOf('Chrome') <= 0) ? true : false;
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  async ngOnInit() {
    await this.rest.getStorage('user').then((val) => {
      this.user = val;
      this.currentUserId = this.user.id + '';
    });
    this.subscribeToChanges();
  }

  async onResize(event) {
    try {
      if (event.target.innerWidth < 800 || this.platform.is('android') || this.platform.is('ios')) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.outData.emit({ isMobile: this.isMobile });
    } catch (error) {
      console.log(error);
    }
  }

  gotoDesco() {
    this.util.setToken(null);
    this.util.setUser(null);

    window.localStorage.removeItem('louderbandToken');
    window.localStorage.removeItem('louderbandUser');
    window.localStorage.removeItem('extra');
    window.localStorage.removeItem('oldUser');
    window.localStorage.removeItem('provider');

    this.rest.removeSet('user');
    this.rest.removeSet('accessToken');

    this.gotoHome();
    this.reloadHome();
  }

  reloadHome() {
    window.location.reload();
  }

  gotoEditProfile() {
    this.outData.emit({ editProfile: true });
  }
  presentPopoverDesktop(e: Event) {
    this.popoverDesktop.event = e;
    this.isOpenDesktop = true;
  }
  presentPopoverNotification(e: Event) {
    this.popoverNotification.event = e;
    this.isOpenNotification = true;
    this.selectedNotificationType = 'all';
  }

  presentPopoverNotificationNested(e: Event, item) {
    this.popoverNotificationNested.event = e;
    this.isOpenNotificationNested = true;
    this.itemNotification = item;
  }

  presentModalLogo(e: boolean) {
    this.isOpenModalLogo = e;
  }

  presentPopoverMobile(e: Event) {
    this.popoverMobile.event = e;
    this.isOpenMobile = true;
  }

  onFocus(ev) {
    this.outData.emit({ focus: true });
  }

  gotoOld(dat) {
    this.outData.emit({ old: true, view: dat });
    this.variableNavBarService.onServicePage = true;
    this.variableNavBarService.onCoursePage = false;
    this.variableNavBarService.onChatPage = false;
    this.variableNavBarService.onHomePage = false;
    this.variableNavBarService.updateVarOnSearchBar(false);
  }

  gotoOldAndCloseModal(dat) {
    this.modalController.dismiss().then(() => {
      this.isOpenModalLogo = false;
      this.gotoOld(dat);
    });
  }

  showSearch(ev) {
    this.onFocus(ev);
    this.variableNavBarService.updateVarOnSearchBar(true);
    this.variableNavBarService.onHomePage = false;
    this.variableNavBarService.onServicePage = false;
    this.variableNavBarService.onCoursePage = false;
    this.variableNavBarService.onChatPage = false;
  }


  getClear() {
    this.outData.emit({ focus: true, search: '' });
  }

  gotoInit() {
    this.outData.emit({ login: true });
  }


  gotoHome() {
    this.search = null;
    this.search_section = null;
    this.outData.emit({ home: true, search: null });
    this.outData.emit({ blur: true });
    this.navCtrl.navigateForward('/home');
    this.variableNavBarService.onHomePage = true;
    this.variableNavBarService.onCoursePage = false;
    this.variableNavBarService.onServicePage = false;
    this.variableNavBarService.onChatPage = false;
    this.variableNavBarService.updateVarOnSearchBar(false);
  }

  clearAllChipsFilters() {
    this.search = null;
    this.search_section = null;
    this.outData.emit({ home: true, search: null });
    //this.outData.emit({ blur: true });
    this.navCtrl.navigateForward('/home');
    this.onFocus(null);
  }

  gotoRegister() {
    this.navCtrl.navigateForward('/registro');
  }

  gotoLogin() {
    this.navCtrl.navigateForward('/login');
    this.modalShowRegisterTelco = false;
  }

  gotoCourses(type = null) {
    this.search = null;
    this.search_section = null;
    this.outData.emit({ blur: true });
    this.variableNavBarService.onCoursePage = true;
    this.variableNavBarService.onHomePage = false;
    this.variableNavBarService.onServicePage = false;
    this.variableNavBarService.onChatPage = false;
    this.variableNavBarService.updateVarOnSearchBar(false);
    this.navCtrl.navigateForward('/cursos' + (type ? '?type=' + type : ''));
  }

  gotoCoursesAndCloseModal(type = null) {
    this.modalController.dismiss().then(() => {
      this.isOpenModalLogo = false;
      this.gotoCourses(type);
    });
  }

  gotoChat() {
    this.search = null;
    this.search_section = null;
    this.outData.emit({ blur: true });
    this.variableNavBarService.onChatPage = true;
    this.variableNavBarService.onHomePage = false;
    this.variableNavBarService.onCoursePage = false;
    this.variableNavBarService.onServicePage = false;
    this.variableNavBarService.updateVarOnSearchBar(false);
    if(this.util.token && this.util.user == null && this.util.user.msisdn === null
      || (this.util.token && this.util.user != null && this.util.user.msisdn !== null && (this.email.test(this.util.user.email) === true))){
      this.navCtrl.navigateForward('/mensajes');
    } else if(this.util.token && this.util.user.msisdn !== null && (this.email.test(this.util.user.email) === false)){
      this.showModalRegisterTelco();
    }
  }

  gotoSubscription() {
    // window.open('https://blog.louderband.com/landing-page-cursos/');
    //this.navCtrl.navigateForward('landing/landing-page-cursos');
    this.navCtrl.navigateForward('/suscripciones');
  }

  gotoProfile(val) {
    this.navCtrl.navigateBack('perfiles/' + val.id);
  }

  gotoPro() {
    // window.open('https://blog.louderband.com/landing-page-suscripciones-cuenta-pro/');
    this.navCtrl.navigateForward('landing/landing-page-suscripciones-cuenta-pro');
  }
  gotoSettingsAccount() {
    this.navCtrl.navigateForward('cuenta');
  }

  gotoBitelTerms() {
    // window.open('https://blog.louderband.com/landing-page-suscripciones-cuenta-pro/');
    this.navCtrl.navigateForward('landing/tycbitelperu');
  }

  gotoTerms() {
    // window.open('https://blog.louderband.com/landing-page-suscripciones-cuenta-pro/');
    this.navCtrl.navigateForward('landing/terminos-y-condiciones');
  }

  gotoPolicyPrivacy() {
    // window.open('https://blog.louderband.com/landing-page-suscripciones-cuenta-pro/');
    this.navCtrl.navigateForward('landing/politicas-de-privacidad');
  }
  updateSearchResults(ev) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.search) {

        console.log('Activated', this.activatedRoute); //location.origin

        // const url = this.router.createUrlTree([], {relativeTo: null, queryParams: {search: this.search}}).toString()
        let url = '/home?search=' + encodeURIComponent(this.search);

        if (this.search_section && this.search_section.length) {
          url += '&section=' + this.search_section;
        }

        console.log('From Header', 'URL', url);

        this.location.go(url);

        console.log('Last Location:', this.lastLocation);
        console.log('Current Location:', url);
        let comparedLocation = this.location.isCurrentPathEqualTo(this.lastLocation);
        console.log('Location: ', comparedLocation);

        this.lastLocation = url;

        if (!comparedLocation) {
          console.log('Location', 'Navigating...');
          this.router.navigate(['/home'], { queryParams: { search: this.search, section: this.search_section } });
        }

        this.outData.emit({ search: this.search, search_section: this.search_section });
      } else {
        this.outData.emit({ focus: true, search: '' });

        // const url = this.router.createUrlTree([], {relativeTo: null }).toString()
        let url = '/home';
        this.location.go(url);
      }

    }, 300);
  }

  onBlur() {
    this.outData.emit({ blur: true });
  }

  hideKeyboard() {

  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event:
    KeyboardEvent) {
    this.outData.emit({ search: '' });
    this.search = '';
  }

  showModalAddPost(){
    if(this.util.token && this.util.user == null && this.util.user.msisdn === null
      || (this.util.token && this.util.user != null && this.util.user.msisdn !== null && (this.email.test(this.util.user.email) === true))){
      this.sharedVarModalService.updateVarShowModal(true);
    } else if(this.util.token && this.util.user.msisdn !== null && (this.email.test(this.util.user.email) === false)){
      this.showModalRegisterTelco();
    }
  }
  showModalRegisterTelco() {
    this.modalShowRegisterTelco = !this.modalShowRegisterTelco;
  }

  getReadNotifications() {
    this.selectedNotificationType = 'read';
    this.filteredNotifications = this.notifications.filter(value => value.readed);
  }

  getUnreadNotifications() {
    this.selectedNotificationType = 'unread';
    this.filteredNotifications = this.notifications.filter(value => !value.readed);
  }

  getAllNotifications() {
    this.selectedNotificationType = 'all';
    this.filteredNotifications = this.notifications;
  }

  getTime(notifTime) {
    const currentTime = new Date().getTime();
    const timeElapsed = Math.abs(currentTime - notifTime);

    const seconds = Math.round(timeElapsed / 1000);
    const minutes = Math.round(timeElapsed / 1000 / 60);
    const hours = Math.round(timeElapsed / 1000 / 60 / 60);
    const days = Math.round(timeElapsed / 1000 / 60 / 60/ 24);

    if (days > 1) {
      return 'Hace ' + days + ' días';
    }
    if (days === 1) {
      return 'Hace ' + days + ' día';
    }
    if (hours > 1) {
      return 'Hace ' + hours + ' horas';
    }
    if (hours === 1) {
      return 'Hace ' + hours + ' hora';
    }
    if (minutes > 0) {
      return 'Hace ' + minutes + ' minutos';
    }

    return 'Hace ' + seconds + ' segundos';
  }

  subscribeToChanges() {
    const q = query(collection(this.util.db, "notificaciones"), where("receiver", "==", this.currentUserId),
      limit(10));
    this.unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((notif) => {
        if (notif.type === 'removed') {
          this.notifications = [...this.notifications.filter(value => value.id !== notif.doc.id)];
          this.filteredNotifications = this.filteredNotifications.filter(value => value.id !== notif.doc.id);
          return;
        }

        this.notifications = [{ id: notif.doc.id, ...notif.doc.data() }, ...this.notifications
          .filter(value => value.id !== notif.doc.id)];

        this.filteredNotifications = this.filteredNotifications.filter(value => value.id !== notif.doc.id);
        if (this.selectedNotificationType === 'all'
          || (this.selectedNotificationType === 'read' && notif.doc.data().readed)
          || (this.selectedNotificationType === 'unread' && notif.doc.data().readed)) {
          this.filteredNotifications = [{ id: notif.doc.id, ...notif.doc.data() }, ...this.filteredNotifications];
        }
      });
    });
  }

   goToChat(item) {
     if (!item.readed) {
       updateDoc(doc(this.util.db, 'notificaciones', item.id), {
         readed: true
       });
     }
    this.popoverNotification.dismiss();
    this.navCtrl.navigateForward('/mensajes/usuario/' + item.emisor);
  }

  goToNotifications() {
    this.popoverNotification.dismiss();
    this.navCtrl.navigateForward('/notificaciones');
  }

  markAsRead() {
    if (!this.itemNotification.readed) {
      updateDoc(doc(this.util.db, 'notificaciones', this.itemNotification.id), {
        readed: true
      });
    }
    this.popoverNotificationNested.dismiss();
  }

  markAsUnread() {
    if (this.itemNotification.readed) {
      updateDoc(doc(this.util.db, 'notificaciones', this.itemNotification.id), {
        readed: false
      });
    }
    this.popoverNotificationNested.dismiss();
  }

  deletedNotification() {
    if (this.itemNotification.id) {
      deleteDoc(doc(this.util.db, 'notificaciones', this.itemNotification.id));
    }
    this.popoverNotificationNested.dismiss();
  }

  showNewNotification() {
    return this.notifications && this.notifications.some(value => !value.readed);
  }

  onScroll() {
    console.log('scroll');
  }
}
