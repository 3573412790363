import { ModalController, LoadingController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
// Pixel library importing
import { PixelService } from 'ngx-pixel';

@Component({
  selector: 'app-login-c',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  password: any;
  email: any;

  constructor(public modalCtrl: ModalController, 
    private pixel: PixelService,
    public loading: LoadingController) { }

  ngOnInit() { }

  askmeLater() {
    this.modalCtrl.dismiss(false);
  }

  next() {
    this.modalCtrl.dismiss({
      email: this.email,
      password: this.password
    });
  }


  validData() {
    const email = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");
    if (email.test(this.email)) {
      return true;
    } else {
      return false;
    }
  }

}
