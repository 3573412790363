import { UtilService } from './util.service';
import { Platform } from '@ionic/angular';
import { Injectable, NgZone } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    private router: Router,
    public util: UtilService,
    public zone: NgZone,
    public platform: Platform) { }

  initPush() {
    if (this.platform.is('capacitor')) {
      this.registerPush();
    }
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        console.log('My token: ' + JSON.stringify(token));
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (data.detailsId) {
          console.log('detailsId: ');
          this.util.setDataFCM(data.detailsId);
          this.zone.run(() => {
            this.router.navigateByUrl(`/search/input/${data.detailsId}`);
          });
        }
      }
    );
  }
}
