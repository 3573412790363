import { LoginComponent } from 'src/app/modal/login/login.component';
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
//import { IonCustomScrollbarModule } from "ion-custom-scrollbar";
import { DataExpandComponent } from "./data-expand/data-expand.component";
import { SearchExpandComponent } from "./search-expand/search-expand.component";
import { VideoComponent } from './video/video.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { MoreInfoComponent } from './more-info/more-info.component';
import { SwiperModule } from 'swiper/angular';
import { AddDiscComponent } from './add-disc/add-disc.component';
import { AddPostComponent } from './add-post/add-post.component';

@NgModule({
    declarations: [
        SearchExpandComponent,
        DataExpandComponent,
        LoginComponent,
        VideoComponent,
        MoreInfoComponent,
        AddDiscComponent,
        AddPostComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        //IonCustomScrollbarModule,
        IonicModule,
        GooglePlaceModule,
        TranslateModule,
        SwiperModule
    ],
    exports: [
        SearchExpandComponent,
        DataExpandComponent,
        LoginComponent,
        VideoComponent,
        MoreInfoComponent,
        AddDiscComponent,
        AddPostComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class ModalModule { }
