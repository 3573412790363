import { UtilService } from './../../services/util.service';
import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  optionsFilter: any[] = [];
  langH: any;
  category_id: any;


  constructor(
    public navParams: NavParams,
    private translate: TranslateService,
    public util: UtilService,
    public popOverCtrl: PopoverController) {
    this.optionsFilter = this.navParams.data.optionsFilter;
    this.langH = this.navParams.data.langH;
  }

  ngOnInit() {
    console.log(this.langH, this.optionsFilter);
  }

  async filter(ita) {
    console.log(ita);
    this.category_id = ita.category_id;
    setTimeout(() => {
      this.popOverCtrl.dismiss({ category: ita });
    }, 400);
   
  }


  closeF() {
    this.popOverCtrl.dismiss();
  }

}
