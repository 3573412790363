import {ModalController, NavController, NavParams} from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {RestService} from "../../services/rest.service";
import {UtilService} from "../../services/util.service";


@Component({
  selector: 'app-search-expand',
  templateUrl: './search-expand.component.html',
  styleUrls: ['./search-expand.component.scss'],
})
export class SearchExpandComponent implements OnInit {
  option: any;
  step = 0;
  customPopoverOptions: any = {
    cssClass: 'pop-select'
  };
  openPopSelect: boolean;
  isOld = true;
  isYoung: boolean;
  service: any;
  service2: any;
  country: string = '';
  city: any;
  userAddress: string = '';
  place_id: string = '0';
  userLatitude: string = '0';
  userLongitude: string = '0';
  instrumento_id = environment.instrumento_id;
  ingeniero_id = environment.ing_sonido;
  user_type = environment.null_user;
  banda_id = environment.banda_id;
  custmScroll = `@media(pointer: fine) {
    ::-webkit-scrollbar {
      width: 12px !important;
      background: rgb(238, 238, 238) !important;
    }
    ::-webkit-scrollbar-track {
      border-radius: 25px !important;
      background: rgb(238, 238, 238) !important;
    }
    ::-webkit-scrollbar-track:hover {
      //background: #555;
    }
    ::-webkit-scrollbar-thumb {
    	//border-radius: 25px !important;
      background: #3C3C3B;
      width: 2px !important;
    }
    ::-webkit-scrollbar-thumb:hover {
      //background: #eee
    }
    .inner-scroll {
      scrollbar-width: thin
    }
}`
  isMobile: any;
  categories: any = [];
  categoriesParents: any;
  categoriesSons: any;
  learnMusic: any;
  hireProfessional: any;
  record: any;
  profiles: any;
  ages: any;
  levels: any;
  levels_prices: any;
  genres: any;
  item: any;
  set: boolean = false;
  item2: any;
  userTypes: any;
  serviceTypes: any;
  instruments: any;
  services_ing: any;
  servicesLastOption: any;
  here3: boolean;
  instrumentsSelected: any[] = [];
  instrumentsSelectedID: any[] = [];
  genresSelected: any[] = [];
  ingSoundSelected: any[] = [];
  serviceSelected: any;
  form: FormGroup;
  optionany: any = '-1';
  modalShow: boolean;
  selectedProfession: string;
  selectedCategoryRecord: any;
  frontIdSearch: any;
  selectedAge: any;
  user: any;
  profilesServices: any;
  levelsServices: any;

  constructor(
    public util: UtilService,
    public modalCtrl: ModalController,
    public paras: NavParams,
    private formBuilder: FormBuilder,
    public rest: RestService,
    public navCtrl: NavController) {
    this.isMobile = this.paras.data.isMobile;
    this.profiles = this.paras.data.profiles;
    this.categories = this.paras.data.categories;
    this.learnMusic = this.profiles.filter(profile => profile.type === 'USU' && profile.auth_item_id != 'musico');
    //this.record = this.profiles.filter(profile => profile.type === 'ENT');
    //this.hireProfessional = this.profiles.filter(profile => (profile.type === 'USU' || profile.type === 'UNI') && profile.auth_item_id !== 'instrumento');
    this.instruments = this.categories.filter(category => category.parent === environment.instrumento_id);
    this.services_ing = this.categories.filter(category => category.parent === environment.ing_sonido);
    this.servicesLastOption = this.categories.filter(category => category.parent === environment.ing_sonido && category.name !== 'Ingeniero de audio');
    this.ages = this.paras.data.ages;
    this.levels = this.paras.data.levels;
    this.levels_prices = this.paras.data.levels_prices;
    this.genres = this.paras.data.genres;
    //this.userTypes = this.paras.data.userTypes;
    this.serviceTypes = this.paras.data.serviceTypes;
    this.item = { online_class: false, presential_class: false, on_demand_class: false, category: environment.null_category, user_type: environment.null_user };
    this.item2 = {};

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      text: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getProfilesService();
    this.getLevelsService();
    this.rest.getStorage('front_option').then(optionValue => {
      if (optionValue) {
        this.option = optionValue;
        this.rest.removeSet('front_option');
        this.rest.getStorage('idSearch').then(searchValue => {
          if (searchValue) {
            this.frontIdSearch = searchValue;
            this.rest.removeSet('idSearch');
            this.getDataEditPost();
          }
        });
      }
    });
  }

  getDiv(value: number, max: number) {
    if (value >= max) {
      return '100%';
    } else {
      const x = ((value * 100) / max);
      return String(x + '%');
    }
  }

  valid1() {
    if (this.item && this.item.age && this.item.category.profile_id >= 0 && this.item.level >= 0) {
      return true;
    } else {
      return false;
    }
  }

  valid2() {
    if (this.item && this.item.level && this.item.category && (this.instrumentsSelected.length >= 0 || this.user_type >= 0)) {
      return true;
    } else {
      return false;
    }
  }

  valid4() {
    if (this.item.level && this.item.category && this.userAddress != '') {
      return true;
    } else {
      return false;
    }
  }

  valid3() {
    if (this.item && this.item.level && this.instrumentsSelected.length >= 0 && this.userAddress != '') {
      return true;
    } else {
      return false;
    }
  }

  public hideFieldByUserType(field: string) {
    if (this.item2.selectedUserType) {
      if (this.item2.selectedUserType[field] && this.item2.selectedUserType[field] == 'N') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  validAddress() {
    if ((this.item.presential_class || this.item.online_class) || (this.item.on_demand_class && this.userAddress != '')) {
      return true;
    } else {
      return false;
    }
  }

  next1x() {
    console.log(this.valid2(), this.item);
    if (this.validAddress()) {
      this.step++;
      this.set = false;

    }
  }

  next1() {
    this.step = 4;
    //this.modalCtrl.dismiss(this.item);
  }

  nextOp2() {
    if (this.valid2()) {
      this.step++;
      this.set = false;

    }
  }

  nextOp3() {
    if (this.valid3()) {
      this.step = 4;
      this.set = false;

    }
  }

  nextOp4() {
    console.log(this.valid4(), this.item); //TODO: Validar antes de darle siguiente
    if (this.valid4()) {
      this.step = 4;
      this.set = false;

    }
  }
  next() {
    if (this.valid1()) {
      this.step++;
      this.set = false;
    }

  }

  prev() {
    if (this.step >= 1){
      this.step = 0;
    }
    if(this.step === 4){
      this.step = 1;
    }
  }

  setOld() {
    this.isOld = true;
    this.isYoung = false;
  }

  setYoung() {
    this.isOld = false;
    this.isYoung = true;
  }

  setOp(val) {
    this.option = val;
    if (val == 3) {
      //this.option = 3;
      for (const iterator of this.userTypes) {
        if (iterator.auth_item_id === 'musico' || iterator.auth_item_id === 'musico') {
          this.item.category = iterator;
          this.item2.selectedUserType = iterator;
          this.item.user_type = iterator.user_type_id;
          this.item.user_type = iterator.user_type_id;
          this.here3 = true;
        }
      }
      /*for (const iterator of this.profiles) {
        if (iterator.auth_item_id === 'musico' || iterator.auth_item_id === 'musico') {
          this.item.category = iterator;
        }
      }*/
    }
  }

  openSelect() {
    this.openPopSelect = true;
  }

  onChangeProfessional(e: any) {
    this.openPopSelect = false;
    this.item.category = e.target.value;
    this.selectedProfession = e.target.value.auth_item_id;
  }

  onChangeAge(e: any) {
    this.openPopSelect = false;
    this.item.age = e.target.value;
    //this.item.age_range_id = e.target.value;
  }

  onChangeCategoryRecord(e: any) {
    this.openPopSelect = false;
    this.item.category = e.target.value;
    this.selectedCategoryRecord = e.target.value.auth_item_id;
  }

  onChangeInstrument(e: any) {
    console.log('onChangeInstrument: ' + JSON.stringify(e.detail.value));
    this.openPopSelect = false;
    this.instrumentsSelected = [e.detail.value];
  }

  onChangeInstrumentMultiple(e: any) {
    console.log('onChangeInstrumentMultiple: ' + JSON.stringify(e.detail.value));
    this.openPopSelect = false;
    this.instrumentsSelected = e.detail.value;
    this.item.instrument = e.detail.value;
  }

  onChangeGenreItem(e: any) {
    this.openPopSelect = false;
    this.genresSelected = e.target.value;
  }

  onChangeGenreItem2(e: any) {
    this.openPopSelect = false;
    this.item2.genre = e.target.value;
    this.genresSelected = e.target.value;
  }

  helpCahnge(e: any) {
    this.openPopSelect = false;
  }

  addingInstrument(ev) {
    if (!this.instrumentsSelected.includes(value => value.name === ev.detail.value.name)) {
      this.instrumentsSelected = [...this.instrumentsSelected, ev.detail.value];
    }
  }

  addingIngSound(ev) {
    if (!this.ingSoundSelected.includes(value => value.name === ev.detail.value.name)) {
      this.ingSoundSelected = [...this.ingSoundSelected, ev.detail.value];
    }
  }

  deleteSelectedInstrument(item) {
    this.instrumentsSelected = this.instrumentsSelected.filter(filter => filter.name !== item.name);
  }

  deleteSelectedIngSound(item) {
    this.ingSoundSelected = this.ingSoundSelected.filter(filter => filter.name !== item.name);
  }

  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address;
    //let addresArray = this.userAddress.split(",");
    this.place_id = address.place_id;
    this.userLatitude = address.geometry.location.lat();
    this.userLongitude = address.geometry.location.lng();
    //addresArray = this.userAddress.split(",");
    //this.country = addresArray[addresArray.length - 1].trim();
    this.country = this.userAddress;
  }

  getA() {
    this.step++;
    // this.modalCtrl.dismiss();
  }

  seeR() {
    this.step++;
    //this.modalCtrl.dismiss();
  }

  sendData() {
    this.modalCtrl.dismiss(true);
  }

  back() {
    this.modalCtrl.dismiss(false);
  }

  backOp() {
    //this.option = null;
    //this.here3 = false;
    /*if (this.step >= 1) {
      this.step--;
      this.item.user_type = null;
      this.item.genre = null;
      this.item.service = null;
      if (this.option >= 3) {
        this.step -= 2;
      }
    } else {
      this.clear();
    }*/
    this.clear();
  }

  clear() {
    this.option = null;
    this.step = 0;
    this.here3 = false;
    this.instrumentsSelected = [];
    this.genresSelected = [];
    this.ingSoundSelected = [];
    this.item.presential_class = false;
    this.item.online_class = false;
    this.item.on_demand_class = false;
    this.item.age = null;
    this.item.category = null;
    this.item.level = null;
    this.item.instrument = null;
    this.item.service = null;
    this.item.user_type = null;
    this.item.genre = null;
    this.userAddress = '';
    this.place_id = '0';
    this.userLatitude = '0';
    this.userLongitude = '0';
    this.country = '';
    this.instrumento_id = environment.instrumento_id;
    this.ingeniero_id = environment.ing_sonido;
    this.user_type = environment.null_user;
    this.selectedProfession = null;
    this.frontIdSearch = null;
  }
  askmeLater() {
    this.modalCtrl.dismiss(false);
  }



  setType(op) {
    this.set = false;
    this.item[op] = !this.item[op];
  }

  sendBack() {
    console.log(this.instrumentsSelected.map(value => ({ instrument_id: value.instrument_id})));
    this.modalCtrl.dismiss({
      ...this.item,
      user_type_id: this.item.category.user_type_id,
      place_id: this.place_id,
      address: this.userAddress,
      latitude: this.userLatitude,
      longitude: this.userLongitude,
      ...this.form.value,
      option: this.option,
      instruments_selected: this.instrumentsSelected.map(value => ({ instrument_id: value})),
      genres: this.genresSelected,
      ing_sound_selected: this.ingSoundSelected,
      country: this.country ? this.country : 'none',
      ing_sound_search: (this.item.category.profile_id === environment.ing_sonido) ? 'S' : 'N',
      id_edit: this.frontIdSearch
    });
  }

  sendMessage() {
    //TODO
  }

  getProfilesService() {
    this.rest._get('services/user-types').subscribe(response => {
      this.profilesServices = response.body;
      this.record = this.profilesServices.items.filter(service => service.type === 'ENT');
      this.hireProfessional = this.profilesServices.items.filter(service => (service.type === 'USU' || service.type === 'UNI') && service.auth_item_id !== 'instrumento');
      this.userTypes = this.profilesServices.items.filter(service => service.user_type_id === 3);
      console.log('SERVICES',this.record);
    });
  }

  getLevelsService() {
    this.rest._get('services/levels').subscribe(response => {
      const levels: any = response.body;
      this.levelsServices = levels.data.levels;
      console.log('LEVELS',this.levelsServices);
    });
  }

  getDataEditPost() {
    switch (this.option){
      case '1' :
        this.rest._get('lessons/searches/' + this.frontIdSearch).subscribe(response => {
          const search: any = response.body;
          this.item.category = this.learnMusic.find(value => value.auth_item_id === search.profile);
          this.instrumentsSelected = search.instruments.map(value => value.instrument_id);
          this.item.service = this.instrumentsSelected;
          this.selectedProfession = this.item.category?.auth_item_id;
          this.item.level = search.level_id;
          this.item.age = search.age_range_id;
          this.item.online_class = search.online;
          this.item.presential_class = search.place_id !== '0';
          this.userAddress = search.country === 'none' ? search.country = '' : search.country;
          console.log('address', this.userAddress);
          //this.item.on_demand_class = search. //TODO: no esta On demand class en la api.
          this.form = this.formBuilder.group({
            name: [search.title, Validators.required],
            text: [search.comments, Validators.required],
          });
        }, (error) => {
          console.error('error: ', error);
        });
        break;
      case '2' :
        this.rest._get('services/searches/' + this.frontIdSearch).subscribe(response => {
          const search: any = response.body;
          this.item.category = this.hireProfessional.find(value => value.auth_item_id === search.userType.auth_item_id);
          this.selectedProfession = this.item.category?.auth_item_id;
          this.instrumentsSelected = search.instruments.map(value => value.instrument_id);
          this.item.instrument = this.instrumentsSelected;
          this.genresSelected = search.genres.map(value => value.genre_id);
          this.item.genre =  this.genresSelected;
          this.item.level = search.level_id;
          this.ingSoundSelected = this.services_ing.find(value => value.auth_item_id === search.ing_sound_selected);
          this.item.online_class = search.online;
          this.item.presential_class = search.place_id !== '0';
          this.userAddress = search.country === 'none' ? search.country = '' : search.country;
          this.form = this.formBuilder.group({
            name: [search.title, Validators.required],
            text: [search.description, Validators.required],
          });
        }, (error) => {
          console.error('error: ', error);
        });
        break;
      case '3' :
        this.rest._get('services/searches/' + this.frontIdSearch).subscribe(response => {
          const search: any = response.body;
          this.setOp('3');
          //this.item2.selectedUserType = this.hireProfessional.find(value => value.auth_item_id === search.userType.auth_item_id);
          this.instrumentsSelected = search.instruments.map(value => value.instrument_id);
          this.item.instrument = this.instrumentsSelected;
          this.genresSelected = search.genres.map(value => value.genre_id);
          this.item.genre =  this.genresSelected;
          this.item.level = search.level_id;
          //this.ingSoundSelected = this.services_ing.find(value => value.auth_item_id === search.ing_sound_selected);
          this.item.online_class = search.online;
          this.item.presential_class = search.place_id !== '0';
          this.userAddress = search.country === 'none' ? search.country = '' : search.country;
          this.form = this.formBuilder.group({
            name: [search.title, Validators.required],
            text: [search.description, Validators.required],
          });
        }, (error) => {
          console.error('error: ', error);
        });
        break;
      case '4' :
        this.rest._get('services/searches/' + this.frontIdSearch).subscribe(response => {
          const search: any = response.body;
          this.item.category = this.record.find(value => value.auth_item_id === search.userType.auth_item_id);
          this.selectedCategoryRecord = this.item.category?.auth_item_id;
          this.item.level = search.level_id;
          this.userAddress = search.country === 'none' ? search.country = '' : search.country;
          this.form = this.formBuilder.group({
            name: [search.title, Validators.required],
            text: [search.description, Validators.required],
          });
        }, (error) => {
          console.error('error: ', error);
        });
        break;
    }
  }

}
