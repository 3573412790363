import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, UrlTree} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollHeaderService {
  private _headerTransparent = false;
  private hasQueryParams = false;
  private url = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const queryParams = this.route.snapshot.queryParams;
      this.hasQueryParams = Object.keys(queryParams).length > 0;
      this.url = event.url;
      this._headerTransparent = this.checkTransparentUrl();
    });
  }

  // scrolled = true when the user scrolls down || when the user is on the home page and has no query params
  // scrolled = false when the user scrolls up || when the user is on the home page and has query params

  get headerTransparent() {
    return this._headerTransparent;
  }

  set headerTransparent(value: boolean) {
    this._headerTransparent = this.checkTransparentUrl() && value;
  }

  private checkTransparentUrl() {
    return (this.url === '/home' && !this.hasQueryParams) || this.url === '/';
  }
}
