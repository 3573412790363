import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-data-expand',
  templateUrl: './data-expand.component.html',
  styleUrls: ['./data-expand.component.scss'],
})
export class DataExpandComponent implements OnInit {
  option: any;
  step = 0;
  customPopoverOptions: any = {
    cssClass: 'pop-select'
  };
  openPopSelect: boolean;
  isOld = true;
  isYoung: boolean;

  custmScroll = `@media(pointer: fine) {
    ::-webkit-scrollbar {
      width: 12px !important;
      background: rgb(238, 238, 238) !important;
    }
    ::-webkit-scrollbar-track {
      border-radius: 25px !important;
      background: rgb(238, 238, 238) !important;
    }
    ::-webkit-scrollbar-track:hover {
      //background: #555;
    }
    ::-webkit-scrollbar-thumb {
    	//border-radius: 25px !important;
      background: #3C3C3B;
      width: 2px !important;
    }
    ::-webkit-scrollbar-thumb:hover {
      //background: #eee
    }
    .inner-scroll {
      scrollbar-width: thin
    }
}`

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() { }

  getDiv(value: number, max: number) {
    if (value >= max) {
      return '100%';
    } else {
      const x = ((value * 100) / max);
      return String(x + '%');
    }
  }

  next() {
    // this.step++;
    this.modalCtrl.dismiss();
  }

  setOld() {
    this.isOld = true;
    this.isYoung = false;
  }

  setYoung() {
    this.isOld = false;
    this.isYoung = true;
  }

  setOp(val) {
    this.option = val;
  }

  openSelect() {
    this.openPopSelect = true;
  }

  helpCahnge() {
    this.openPopSelect = false;
  }


  getA() {
    this.step++;
    // this.modalCtrl.dismiss();
  }

  seeR() {
    this.step++;
    //this.modalCtrl.dismiss();
  }

  sendData() {
    this.modalCtrl.dismiss(true);
  }

  askmeLater() {
    this.modalCtrl.dismiss(false);
  }




}
