import { UtilService } from './../../services/util.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavController } from '@ionic/angular';


@Component({
  selector: 'course-user',
  templateUrl: './course-user-louderband.component.html',
  styleUrls: ['./course-user-louderband.component.scss'],
})
export class CouserUserLouderbandComponent implements OnInit {
  @Input() isMobile: any;
  @Input() user: any;


  constructor(
    private translate: TranslateService,
    public util: UtilService,
    public navCtrl: NavController
  ) { }

  ngOnInit() { }


  onFocus(ev) {

  }

  gotoProfile(val) {
    console.log(val);
    this.navCtrl.navigateBack('perfiles/'+ val.id);
  }

  updateSearchResults(ev) {

  }

  onBlur(ev) {

  }

}
