import { LangService } from './services/lang.service';
import { Component } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform, NavController } from '@ionic/angular';
import { EventsService } from './services/events.service';
import { FcmService } from './services/fcm.service';
import { RestService } from './services/rest.service';
import { UtilService } from './services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { registerLocaleData } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import en from '@angular/common/locales/en';
import es from '@angular/common/locales/es';

import { Storage } from '@ionic/storage-angular';
registerLocaleData(en);
registerLocaleData(es);

import * as firebase from 'firebase/app';
import { getFirestore } from "firebase/firestore";

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private storage: Storage,
    private platform: Platform,
    public events: EventsService,
    private fcmService: FcmService,
    public navCtrl: NavController,
    public rest: RestService,
    public util: UtilService,
    public translate: TranslateService,
    public langService: LangService,
    public activatedRoute: ActivatedRoute
  ) {
    this.init();
  }


  async init() {
    await this.storage.create();
    this.translate.addLangs(['en', 'es']);

    let language = (navigator.language) ? (navigator.language).substring(0, 2) : 'es';

    language = ['en', 'es'].includes(language) ? language : 'en';

    console.log('Browser language: ', language);

    this.translate.setDefaultLang(language);
    this.startApp();
    this.reloadTrans(language);
  }

  startApp() {
    this.platform.ready().then(() => {
      GoogleAuth.initialize({
        clientId: '798973480047-mqrm3lmtrkijvbmokm4i67ks0hhd7ltf.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
      /* if(this.platform.is('capacitor')) {
         if (this.platform.is('ios')) {
           StatusBar.setStyle({ style: Style.Dark });
         } else {
           StatusBar.setBackgroundColor({ color: '#00693e' });
           StatusBar.setStyle({ style: Style.Dark });
         }
         SplashScreen.hide();
       }*/


      // Trigger the push setup
      // this.fcmService.initPush();
      this.rest.getStorage('user').then((dat) => {
        if (dat) {
          setTimeout(() => {
            const newLang = this.langService.getTranslateLanguage();
            this.util.setUser(dat);
            this.reloadTrans(newLang);
            // this.navCtrl.navigateRoot('/home');
          }, 800);
        } else {
          // this.navCtrl.navigateRoot('login');
        }
      });

      const louderbandToken = window.localStorage.getItem('louderbandToken');

      if (louderbandToken) {
        this.util.setToken(louderbandToken);
      }

      const louderbandUser = window.localStorage.getItem('louderbandUser');

      if (louderbandUser) {
        this.util.setUser(JSON.parse(louderbandUser));
      }

      const app = firebase.initializeApp(environment.firebase);
      const db = getFirestore(app);
      this.util.setFirebase(app, db);
    });
  }

  private reloadTrans(lang) {
    console.log('lang', lang);
    this.translate.use(lang);
    this.langService.setTranslateLanguage(lang);
  }

  translateLanguageTo(lang: string) {
    this.translate.use(lang);
  }

}
