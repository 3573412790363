import { Component } from '@angular/core';
import { NavController, NavParams, PopoverController } from '@ionic/angular';

/**
 * Generated class for the OptionDiscPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

 @Component({
  selector: 'app-option-disc',
  templateUrl: './option-disc.html',
  styleUrls: ['./option-disc.scss']
})
export class OptionDiscComponent {
  list: any;
  mode: any;
  radio: any;
  instrument: any;
  listO: any;
  prev: any;
  isFirst = true;
  valueRadio: any;

  constructor(
    public navCtrl: NavController,
    public viewCtrl: PopoverController,
    public navParams: NavParams) {
    this.list = this.navParams.get('list');
    this.listO = JSON.parse(JSON.stringify(this.list));
    this.mode = this.navParams.get('mode');
    this.prev = this.navParams.get('prev');
    console.log('prev ' + this.prev);
    if (this.prev) {
      if (this.mode == 'pro') {
        this.radio = true;
      }else{
        this.list = this.prev;
        this.listO = JSON.parse(JSON.stringify(this.list));
      }
    }
  }

  ionViewDidLoad() {
    console.log(this.list);
    console.log(this.mode);

    console.log('ionViewDidLoad OptionDiscPage');
  }

  select(value) {
    console.log(value);
    this.valueRadio = value;
    //this.viewCtrl.dismiss({ value: value })
  }
  changeCheckRadio() {
    this.valueRadio = this.radio;
  }

  selectP() {
    this.viewCtrl.dismiss({ value: this.radio })
  }

  selectM() {
    this.viewCtrl.dismiss({ value: this.instrument })
  }

  selectRad() {
    this.radio = !this.radio;
  }

  close() {
    this.viewCtrl.dismiss();
  }

  selectList() {
    let aux = [];
    let userMode = '';
    for (let index = 0; index < this.list.length; index++) {
      if (this.list[index].check) {
        aux.push(this.list[index].name);
        if (this.list[index].person) {
          userMode = 'person'
        } else {
          userMode = 'no-person'
        }
      }

    }
    let values = aux.toString();
    this.viewCtrl.dismiss({ value: values, userMode: userMode, getO: this.list })
  }
  changeCheck() {
    console.log(this.list);
  }

  setCheck(value: any, index: any) {
    console.log(value);
    console.log(this.listO);
    console.log(this.list);
    this.list = this.listO;
    if (this.isFirst) {
      this.isFirst = false;
      this.list[index].check = !this.list[index].check;
      //this.setCheck(value, 0);
    }
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].disabled = false;
    }
    if (value.person && value.check) {
      for (let i = 0; i < this.list.length; i++) {
        if (!this.list[i].person) {
          this.list[i].disabled = true;
          this.list[i].check = false;
        } else {
          this.list[i].disabled = false;
        }
      }
    }
    if (!value.person && value.check) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].person) {
          this.list[i].disabled = true;
          this.list[i].check = false;
        } else {
          this.list[i].disabled = false;
        }
      }
    }

    //this.list[index].check = !this.list[index].check;
  }

}
